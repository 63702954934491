body {
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  background-color: #faf9fd;

  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
}

* {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Urbanist", sans-serif;
  font-weight: bold;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-family: "Work Sans", sans-serif;

  font-size: 1rem;
  line-height: 1.6;
}

a {
  font-family: "Work Sans", sans-serif;

  font-size: 1rem;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

button:hover {
  @media screen and (min-width: 998px) {
    transition: all 0.3s ease !important;
    transform: scale(1.05) !important;
  }
  cursor: pointer;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: #5a5172;
}
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.875rem;
  }

  a {
    font-size: 0.875rem;
  }
}
