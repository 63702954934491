@import "./variables";

.banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $background-dark;
  h1 {
    color: $white;
    padding: 100px 0 0 0;
  }
}
