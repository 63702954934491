@import "./variables";

.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  &:active {
    color: inherit;
  }
  .image {
    width: 100%;
    background-color: $white;
    padding: 200px 0;
    background-position: center;
    position: relative;
    background-size: cover;

    .discount {
      position: absolute;
      top: 10px;
      left: 10px;
      padding: 5px;
      background-color: $background-dark;
      color: white;
      z-index: 1;
    }

    .top {
      position: absolute;
      top: 50px;
      left: 10px;
      padding: 5px;
      background-color: $background-dark;
      color: white;
      z-index: 1;
    }
    .noStock {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      color: white;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        padding: 5px;
        background-color: $accent-purple;
        color: white;
        width: 20%;
        text-align: center;
      }
    }
  }
  a {
    background-color: $background-dark;
    color: $white;
    padding: 5px;
    width: calc(100% - 10px);
    text-align: center;
  }
  h4 {
    margin: 15px 0 10px 0;
    font-weight: normal;
    color: $text-primary;
    text-align: center;
  }
  p {
    color: $text-primary;
  }
}
