@import "./_variables.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .firstBanner {
    max-width: 1700px;
    width: 95%;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-position: bottom right;
    background-repeat: no-repeat;
    @media screen and (max-width: 750px) {
      flex-direction: column;
    }
    img {
      width: 30%;
      margin-right: 30px;
      @media screen and (max-width: 1300px) {
        width: 40%;
      }
      @media screen and (max-width: 850px) {
        width: 45%;
      }
      @media screen and (max-width: 750px) {
        width: 400px;
        margin: 0;
      }
      @media screen and (max-width: 401px) {
        width: 100%;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      width: 30%;
      margin-left: 30px;
      @media screen and (max-width: 1300px) {
        width: 40%;
      }
      @media screen and (max-width: 850px) {
        width: 45%;
        text-align: center;
        justify-content: center;
        align-items: center;

        margin: 20px 0 0 0;
      }
      @media screen and (max-width: 750px) {
        width: 100%;
      }
      h1,
      p {
        color: $text-primary;
        margin: 25px 0;
      }
    }
  }

  .story {
    margin-top: 50px;
    max-width: 1200px;
    width: 95%;
    background-position: bottom left;
    background-repeat: no-repeat;
    h1 {
      color: $text-primary;
    }
    p {
      color: $text-primary;
      margin: 20px 0;
    }
  }
}
