@import "./variables";

$loader-size: 300px;
$fade-duration: 2s;

.container {
  background-color: $background-light;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .loader {
    width: $loader-size;
    animation: fadeInOut $fade-duration infinite;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
