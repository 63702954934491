@import "./variables";
.container {
  width: 100%;
  margin: 0 auto;
  background-color: $background-light;

  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  .orderLine {
    width: 100%;
    background-color: $background-dark;
    padding: 10px 0px;

    .innerContainer {
      max-width: 1700px;
      width: 95%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @media screen and (max-width: 900px) {
        grid-template-columns: repeat(1, 1fr);
      }
      gap: 16px;
      .order {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        justify-self: start;
        text-align: center;
        p {
          text-align: center;
        }
        @media screen and (max-width: 900px) {
          justify-self: center;
        }

        &:nth-child(2) {
          justify-self: center;
        }
        &:nth-child(3) {
          justify-self: right;
          @media screen and (max-width: 900px) {
            justify-self: center;
            transform: rotate(90deg);
            display: none;
          }
        }

        p {
          color: $text-secondary;
          font-weight: bold;
        }
        svg {
          margin-right: 5px;
        }
        a {
          color: $text-secondary;
          height: fit-content !important;
          align-items: center;
          justify-content: center;
          display: flex;
          svg {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
  .navbar {
    width: 100%;
    .innerContainer {
      max-width: 1700px;
      margin: 0 auto;
      width: 95%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      img {
        width: 100px;
      }
      .links {
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 900px) {
          display: none;
        }
        a {
          &:nth-child(1) {
            margin-right: 50px;
          }
          &:nth-child(4) {
            margin-left: 50px;
          }
          &:nth-child(2),
          &:nth-child(3) {
            margin: 0 50px;
          }
          color: $text-primary;
          text-transform: uppercase;
        }
      }
      .cart {
        display: flex;
        align-items: center;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 900px) {
            margin-right: 5px;
          }
        }
        .burger {
          display: none;
          @media screen and (max-width: 900px) {
            display: block;
          }
        }
      }
    }
  }

  .mobileNavbar {
    width: 100%;
    z-index: 2;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $background-light;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    svg {
      position: absolute;
      right: 30px;
      top: 30px;
    }
    .logo {
      width: 200px;
      margin: 30px 0;

      img {
        width: 100%;
      }
    }
    .mobileLinks {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      a {
        text-transform: uppercase;
        color: $text-primary;
        &:nth-child(1) {
          margin-bottom: 20px;
        }
        &:nth-child(4) {
          margin-top: 20px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          margin: 20px 0px;
        }
      }
    }
  }
}
