@import "./variables";

.card {
  width: calc(100% - 40px);
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-top: 1px solid $primary-color;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
  &:not(:first-of-type) {
    margin-top: 20px;
  }
  .prodData {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 999px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .image {
      padding: 100px 0;
      width: 200px;
      background-color: white;
      margin-left: -20px;
      background-position: center;
      background-size: cover;
      @media screen and (max-width: 999px) {
        padding: 50px 0;
        width: 100px;
        margin: 0;
      }
    }
    .data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 10px;
      width: fit-content;
      .total {
        font-weight: bold;
      }
      @media screen and (max-width: 999px) {
        padding: 0;
      }
      h2 {
        text-wrap: wrap;
        width: fit-content;
      }
      .discount {
        text-decoration: line-through;
      }
    }
  }
  .actions {
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 600px) {
      align-items: flex-start;
    }
    svg {
      margin-top: 10px;
      color: red;
    }
  }
}
