@import "./variables";

.container {
  width: 100%;
  padding: 150px 0;
  background-color: $accent-purple;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    max-width: 500px;
    width: 95%;
  }
  h3,
  h4 {
    color: $white;
    margin: 20px 0;
    width: 95%;
  }
  a {
    color: $white;
    border: 1px solid $white;
    padding: 10px 5px;
  }
}
