@import "../styles/variables";

.crumbs {
  padding-bottom: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    color: $white;
    text-transform: uppercase;
  }
  svg {
    margin: 0 10px;
  }
  p {
    color: $white;
    text-transform: uppercase;
  }
}
