@import "./_variables.scss";

.button {
  padding: 5px 20px;

  background-color: $background-dark;
  color: $white;
  border: none;
  outline: none;
  margin: 0 5px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  @media screen and (max-width: 999px) {
    flex: 0.9;
  }
}
