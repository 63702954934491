@import "./_variables.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 95%;
    max-width: 1700px;
    margin: 30px 0;
    padding: 10px 0;
    @media screen and (max-width: 650px) {
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
    input {
      width: 150px;
      padding: 8px;
      border: 2px solid $accent-purple;
      background-color: $white;
      color: $accent-purple;
      outline: none;
      border-radius: 4px;

      @media screen and (max-width: 650px) {
        margin: 0;
        width: 45%;
      }
    }
    .selects {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media screen and (max-width: 650px) {
        flex-direction: column;
        width: 45%;
      }
      select {
        width: 150px;
        padding: 8px;
        border: 2px solid $accent-purple;
        background-color: $white;
        color: $accent-purple;
        outline: none;
        border-radius: 4px;
        option {
          font-weight: 600;
        }
        option:disabled {
          color: $text-primary !important;
          font-weight: bold;
          font-size: 20px;
        }

        margin-left: 10px;
        @media screen and (max-width: 650px) {
          margin: 0;
          width: 100%;
          &:nth-of-type(1) {
            margin: 10px 0;
          }
        }
      }
    }
  }
  .products {
    width: 95%;
    max-width: 1700px;
    padding: 30px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .paginationWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 50px;
    .pagination {
      list-style: none;
      height: 31.5px;
      width: 31.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2px;
      cursor: pointer;
      .active {
        border: 1px solid $accent-purple;
        border-radius: 7px;
      }

      .pageItem {
        list-style: none;
        padding: 2px 12px;
        height: 31.5px;
        width: 31.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2px;
        color: $accent-purple;
      }
    }
  }
}
