@import "../styles/variables";
.container {
  width: 100%;
  background-color: $light-gray;
  display: flex;
  flex-direction: column;
  align-items: center;

  .innerContainer {
    max-width: 1700px;
    width: 95%;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1150px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 770px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
    .col {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;
      width: fit-content;
      height: 100%;
      @media screen and (max-width: 770px) {
        justify-self: center;
        align-items: center;
        width: 100%;
      }
      &:nth-child(1) {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &:nth-child(2) {
        a {
          color: $text-primary;
          text-transform: uppercase;
          &:nth-child(1) {
            margin-bottom: 5px;
          }
          &:nth-child(5) {
            margin-top: 5px;
          }
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            margin: 5px 0;
          }
        }
        @media screen and (max-width: 770px) {
          margin: 30px 0;
        }
      }
      &:nth-child(3) {
        a {
          color: $text-primary;
          text-transform: uppercase;
          &:nth-child(1) {
            margin-bottom: 5px;
          }
          &:nth-child(5) {
            margin-top: 5px;
          }
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            margin: 5px 0;
          }
        }
        .hidden {
          visibility: hidden;
        }
      }
      &:nth-child(4) {
        a {
          color: $text-primary;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          svg {
            margin-right: 5px;
          }
          &:nth-child(1) {
            margin-bottom: 5px;
          }
          &:nth-child(5) {
            margin-top: 5px;
          }
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            margin: 5px 0;
          }
        }
        .hidden {
          visibility: hidden;
        }
      }
      img {
        width: 150px;
      }
      h5,
      p {
        color: $text-primary;
        font-weight: normal;
      }
      .newsLetter {
        padding: 30px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        position: relative;
        p {
          color: red;
          position: absolute;
          bottom: 10px;
          font-size: 12px;
        }
        @media screen and (max-width: 770px) {
          justify-content: center;
        }
        input {
          width: 80%;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid rgba(90, 81, 114, 0.5);
          outline: none;
          background-color: transparent;
        }
        img {
          width: 10%;
          cursor: pointer;
        }
      }
    }
  }
  .hr {
    width: 100%;
    border-top: 1px solid rgba(90, 81, 114, 0.5);
  }
  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    p {
      color: $text-primary;
      text-align: center;
    }
  }
}
