/* src/styles/fonts.css */

@font-face {
  font-family: "Urbanist";
  src: url("../assets/fonts/Urbanist/Urbanist-Regular.ttf") format("ttf"),
    url("../assets/fonts/Urbanist/Urbanist-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist";
  src: url("../assets/fonts/Urbanist/Urbanist-Bold.ttf") format("ttf"),
    url("../assets/fonts/Urbanist/Urbanist-Bold.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("../assets/fonts/WorkSans/WorkSans-Regular.ttf") format("ttf"),
    url("../assets/fonts/WorkSans/WorkSans-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("../assets/fonts/WorkSans/WorkSans-Bold.ttf") format("ttf"),
    url("../assets/fonts/WorkSans/WorkSans-Bold.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "Urbanist", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Urbanis", sans-serif;
}
p {
  font-family: "Work Sans", sans-serif;
}
