.brandsSwiper {
  width: calc(100% - 40px);
  padding: 50px 20px !important;
  .brands {
    width: 100%;
    text-align: center;
    img {
      width: 100px;
    }
  }
}
