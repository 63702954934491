@import "./_variables.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .innerContainer {
    max-width: 1700px;
    width: 95%;
    margin: 50px auto;
    .product {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70vh;
      @media screen and (max-width: 999px) {
        flex-direction: column;
        height: fit-content;
      }
      .productsImages {
        height: 100%;
        width: 45%;
        @media screen and (max-width: 999px) {
          width: 100%;
        }
        .photos {
          background-position: center;
          background-size: cover;
          @media screen and (max-width: 999px) {
            padding: 150px 0;
          }
        }
      }
      .productData {
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: inherit;
        @media screen and (max-width: 999px) {
          width: 100%;
          margin-top: 30px;
        }
        h1 {
          color: $text-primary;
        }
        .price {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 20px;
          p {
            color: $text-primary;
          }

          .priceWithoutDiscount {
            display: flex;
          }
          .discount {
            text-decoration: line-through;
          }
        }
        .description {
          margin-top: 30px;
          color: $text-primary;
          overflow-y: auto;
          .markDown {
            width: 95%;
            word-break: break-word;
            overflow-wrap: anywhere;
          }
          .origin {
            margin-top: 20px;
          }
        }
        .total {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 20px;
          h4 {
            color: $text-primary;

            &:nth-of-type(2) {
              margin-left: 20px;
            }
          }
        }
        .buttons {
          z-index: 2;
          width: fit-content;
          display: flex;
          flex-direction: row;
          align-items: center;
          @media screen and (max-width: 999px) {
            position: fixed;
            bottom: 0;
            left: 0;
            padding: 20px 10px;
            background-color: whitesmoke;
            width: calc(100% - 20px);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    }
    .relatedPorducts {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      .title {
        color: $text-primary;
      }
    }
  }
}
