$primary-color: #b5add3;
$secondary-color: #d4cce8;
$success-color: #28a745;
$danger-color: #dc3545;
$warning-color: #ffc107;
$info-color: #17a2b8;

$black: #000000;
$white: #ffffff;
$light-gray: #ededef;
$gray: #6c757d;
$dark-gray: #343a40;

$background-light: #faf9fd;
$background-med: #c1badb;
$background-dark: #b5add3;
$background-muted: #d4cce8;

$text-primary: #5a5172;
$text-secondary: #ffffff;
$text-muted: #757575;

$accent-purple: #5a5172;
$accent-green: #218838;
$accent-red: #c82333;

$shadow-light: rgba(0, 0, 0, 0.1);
$shadow-dark: rgba(0, 0, 0, 0.3);
