@import "./_variables.scss";

.button {
  width: 150px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  background-color: $background-dark;
  margin: 20px 0;
  p {
    border-left: 1px solid $white;
    border-right: 1px solid $white;
    color: $white;
  }
  button {
    background-color: transparent;
    outline: none;
    color: $white;

    border: none;
  }
}
