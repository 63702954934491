@import "./_variables.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .banner {
    width: 100%;
    height: 70vh;
    background-color: $primary-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .innerBanner {
      max-width: 650px;
      width: 100%;
      height: inherit;
      background-color: $secondary-color;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $white;
      background-position: center;
      background-size: cover;
      h1 {
        font-weight: 600;
        width: 80%;
        margin: 20px 0;
      }
      p {
        color: $white;
        &:nth-of-type(2) {
          color: $text-primary;
          font-style: italic;
        }
        width: 70%;
      }
      a {
        margin-top: 20px;
        background-color: $accent-purple;
        color: $white;
        padding: 10px 22px;
        text-transform: uppercase;
      }
    }
  }
  .hashtagsSwiper {
    width: calc(100% - 40px);
    padding: 50px 20px;
    .hashtags {
      h4 {
        color: $text-primary;
        font-weight: normal;
        text-align: center;
      }
    }
  }
  .categories {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 99.5%;
    padding: 0 1%;

    justify-content: center;
    @media (max-width: 999px) {
      width: 97.5%;
      padding: 0 2.5%;
    }

    .category {
      background-color: $background-med;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      height: 30vh;
      background-position: center;
      background-size: cover;
      .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        .text {
          padding: 0px 0 20px 20px;
          color: $white;
          p {
            color: $white;
          }
          h4 {
            font-weight: normal;
            margin: 10px 0;
          }
          a {
            color: $white;
            text-transform: uppercase;
            border: 1px solid $white;
            padding: 5px 10px;
          }
        }
      }
    }

    .category1 {
      flex: 0 0 69%;
    }

    .category2 {
      flex: 0 0 30%;
    }

    .category3 {
      flex: 0 0 30%;
    }

    .category4 {
      flex: 0 0 69%;
    }
    .category5 {
      flex: 0 0 100%;
    }

    @media (max-width: 999px) {
      .category {
        flex: 1 0.5 100%;
      }
    }
  }

  .productsContainer {
    max-width: 1700px;
    width: 95%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h1,
    p {
      color: $text-primary;
      font-weight: normal;
    }

    .productsSwiper {
      width: 100%;
      margin-top: 20px;
      .products {
        width: 100%;
      }
    }
  }
  .video {
    width: 100%;
    height: 40vh;
  }
  .instagram {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    p {
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      color: $text-primary;
    }
    h2 {
      color: $text-primary;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      a {
        margin-left: 5px;
        font-size: 2rem;
        color: $text-muted;
        @media (max-width: 768px) {
          font-size: 1.75rem;
        }
      }
    }
    .instagramPosts {
      width: 100%;
      .instagramSwiper {
        width: 100%;
        .instagramPost {
          width: 100%;
          height: 20vh;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          a {
            height: 100%;

            .overlay {
              background-color: rgba(0, 0, 0, 0.4);
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
