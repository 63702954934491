@import "./_variables.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .cart {
    width: 95%;
    margin: 50px 0;
    max-width: 1700px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    @media screen and (max-width: 999px) {
      flex-direction: column;
    }
    .products {
      width: 68%;
      @media screen and (max-width: 999px) {
        width: 100%;
      }
    }
    .billingInfo {
      width: 30%;
      padding: 0px 0;
      position: sticky;
      top: 0;
      @media screen and (max-width: 999px) {
        width: 100%;
        position: unset;
      }

      form {
        width: calc(100% - 40px);
        height: inherit;
        background-color: rgba(212, 204, 232, 0.6);
        padding: 20px;
        @media screen and (max-width: 998px) {
          width: calc(100% - 40px);
          margin-top: 30px;
        }

        h1 {
          color: $text-primary;
        }
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          margin-top: 20px;
          label {
            color: $text-primary;
          }
          input {
            width: calc(100% - 10px);
            padding: 10px 5px;
            outline: none;
            border: 1px solid $primary-color;
            background-color: rgba(212, 204, 232, 0.6);
            color: $text-primary;
          }
          textarea {
            width: calc(100% - 10px);
            padding: 10px 5px;
            outline: none;
            border: 1px solid $primary-color;
            background-color: rgba(212, 204, 232, 0.6);
            resize: none;
            height: 200px;
            color: $text-primary;
          }
          p {
            color: $danger-color;
            font-size: 12px;
          }
        }
        button {
          width: 100%;
          padding: 10px 0;
          background-color: $accent-purple;
          color: $white;
          outline: none;
          border: none;
          margin-top: 20px;
        }
        .loader {
          width: 100%;
          padding: 10px 0;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .billingDetails {
          color: $text-primary;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          h4 {
            display: flex;
            flex-direction: column;
            span {
              font-size: 12px;
              margin-bottom: 20px;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
  .empty {
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    h1 {
      color: $text-primary;
    }
    a {
      background-color: $background-dark;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $white;
      padding: 5px 0;
      width: 170px;
      margin-top: 30px;
      font-weight: bold;

      justify-content: center;
      svg {
        margin-left: 10px;
      }
    }
  }
}
