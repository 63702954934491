@import "./_variables.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .contact {
    width: 95%;
    max-width: 1700px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 50px 0;
    @media screen and (max-width: 998px) {
      flex-direction: column;
    }
    .data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 45%;
      @media screen and (max-width: 998px) {
        width: 100%;
      }
      .logo {
        width: 150px;
        margin-bottom: 20px;
      }
      p {
        color: $text-primary;
      }
      h3 {
        color: $text-primary;
        font-weight: normal;
      }
      h4 {
        color: $text-primary;
        font-weight: normal;
        margin-top: 30px;
      }
      .infos {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 25px;
        width: 100%;

        .data {
          margin-left: 20px;
          h5 {
            font-weight: normal;
            color: $text-primary;
          }
          a {
            color: $text-primary;
          }
        }
      }
      .blankSquare {
        max-width: 400px;
        width: 95%;
        height: 200px;
        background-color: $background-dark;
        margin-top: 30px;
        @media screen and (max-width: 998px) {
          max-width: unset;
          width: 100%;
        }
      }
    }

    form {
      width: calc(45% - 40px);
      height: inherit;
      background-color: rgba(212, 204, 232, 0.6);
      padding: 20px;
      @media screen and (max-width: 998px) {
        width: calc(100% - 40px);
        margin-top: 30px;
      }

      h1 {
        color: $text-primary;
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 20px;
        label {
          color: $text-primary;
        }
        input {
          width: calc(100% - 10px);
          padding: 10px 5px;
          outline: none;
          border: 1px solid $primary-color;
          background-color: rgba(212, 204, 232, 0.6);
          color: $text-primary;
        }
        textarea {
          width: calc(100% - 10px);
          padding: 10px 5px;
          outline: none;
          border: 1px solid $primary-color;
          background-color: rgba(212, 204, 232, 0.6);
          resize: none;
          height: 200px;
          color: $text-primary;
        }
        p {
          color: $danger-color;
          font-size: 12px;
        }
      }
      button {
        width: 100%;
        padding: 10px 0;
        background-color: $accent-purple;
        color: $white;
        outline: none;
        border: none;
        margin-top: 20px;
      }
      .loader {
        width: 100%;
        padding: 10px 0;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  iframe {
    width: 100%;
  }
}
