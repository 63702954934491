@import "./variables";

.wrapper {
  width: 95%;
  max-width: 1700px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  margin: 50px auto;
}
